exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-preview-tsx": () => import("./../../../src/pages/preview.tsx" /* webpackChunkName: "component---src-pages-preview-tsx" */),
  "component---src-templates-education-tsx": () => import("./../../../src/templates/education.tsx" /* webpackChunkName: "component---src-templates-education-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-post-tsx": () => import("./../../../src/templates/post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */),
  "component---src-templates-teammember-tsx": () => import("./../../../src/templates/teammember.tsx" /* webpackChunkName: "component---src-templates-teammember-tsx" */),
  "component---src-templates-training-tsx": () => import("./../../../src/templates/training.tsx" /* webpackChunkName: "component---src-templates-training-tsx" */)
}

